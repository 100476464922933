<script>
import appConfig from "../../app.config";
import { mapState } from "vuex";

export default {
  page: {
    title: "Products",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["mode"]),
  },
  methods: {
    deleteMail() {
      this.removeModal = true;
      var checkall = document.getElementById("checkall");
      document
        .getElementById("delete-record")
        .addEventListener("click", function () {
          document
            .querySelectorAll(".message-list li")
            .forEach(function (element) {
              if (element.classList.contains("active")) {
                element.remove();
              }
            });
          document.getElementById("btn-close").click();
          if (document.getElementById("email-topbar-actions"))
            document.getElementById("email-topbar-actions").style.display =
              "none";
          checkall.indeterminate = false;
          checkall.checked = false;
        });
    },
    checkBoxAll() {
      // checkbox-wrapper-mail
      document
        .querySelectorAll(".checkbox-wrapper-mail input")
        .forEach(function (element) {
          element.addEventListener("click", function (el) {
            if (el.target.checked == true) {
              el.target.closest("li").classList.add("active");
            } else {
              el.target.closest("li").classList.remove("active");
            }
          });
        });

      // checkbox
      var checkall = document.getElementById("checkall");
      var checkboxes = document.querySelectorAll(
        ".checkbox-wrapper-mail input"
      );
      checkboxes.forEach(function (element) {
        element.addEventListener("click", function (event) {
          var checkboxes = document.querySelectorAll(
            ".checkbox-wrapper-mail input"
          );
          var checkedCount = document.querySelectorAll(
            ".checkbox-wrapper-mail input:checked"
          ).length;
          checkall.checked = checkedCount > 0;
          checkall.indeterminate =
            checkedCount > 0 && checkedCount < checkboxes.length;

          if (event.target.closest("li").classList.contains("active")) {
            checkedCount > 0
              ? (document.getElementById("email-topbar-actions").style.display =
                  "block")
              : (document.getElementById("email-topbar-actions").style.display =
                  "none");
          } else {
            checkedCount > 0
              ? (document.getElementById("email-topbar-actions").style.display =
                  "block")
              : (document.getElementById("email-topbar-actions").style.display =
                  "none");
          }
        });
      });

      document.getElementById("email-topbar-actions").style.display = "none";

      // checkbox all
      checkall.addEventListener("click", function (event) {
        var checkboxes = document.querySelectorAll(
          ".checkbox-wrapper-mail input"
        );
        checkboxes.forEach(function (chkbox) {
          if (checkall.checked == true) {
            chkbox.checked = true;
          } else {
            chkbox.checked = false;
          }
          var checkedCount = document.querySelectorAll(
            ".checkbox-wrapper-mail input:checked"
          ).length;
          event.checked = checkedCount > 0;
          event.indeterminate =
            checkedCount > 0 && checkedCount < checkboxes.length;
          if (chkbox.checked == true) {
            chkbox.parentNode.parentNode.parentNode.parentNode.classList.add(
              "active"
            );
            checkedCount > 0
              ? (document.getElementById("email-topbar-actions").style.display =
                  "block")
              : (document.getElementById("email-topbar-actions").style.display =
                  "none");
          } else {
            chkbox.checked = false;
            chkbox.parentNode.parentNode.parentNode.parentNode.classList.remove(
              "active"
            );
            checkedCount > 0
              ? (document.getElementById("email-topbar-actions").style.display =
                  "block")
              : (document.getElementById("email-topbar-actions").style.display =
                  "none");
          }
        });
      });
    },

    scrollToBottom(id) {
      setTimeout(function () {
        var simpleBar = document
          .getElementById(id)
          .querySelector("#chat-conversation .simplebar-content-wrapper")
          ? document
              .getElementById(id)
              .querySelector("#chat-conversation .simplebar-content-wrapper")
          : "";
        var offsetHeight = document.getElementsByClassName(
          "chat-conversation-list"
        )[0]
          ? document
              .getElementById(id)
              .getElementsByClassName("chat-conversation-list")[0]
              .scrollHeight -
            window.innerHeight +
            750
          : 0;
        if (offsetHeight)
          simpleBar.scrollTo({
            top: offsetHeight,
            behavior: "smooth",
          });
      }, 100);
    },

    changeUserScroll() {
      var currentChatId = "users-chat";
      this.scrollToBottom(currentChatId);
    },

    openemail() {
      document.body.classList.add("email-detail-show");
      document
        .querySelectorAll(".message-list li.unread")
        .forEach(function (element) {
          if (element.classList.contains("unread")) {
            event.target.closest("li").classList.remove("unread");
          }
        });
    },
    toggleFavourite(ele) {
      ele.target.closest(".favourite-btn").classList.toggle("active");
    },
    changetab(type) {
      this.tabtype = type;
    },
    changemenu(menu) {
      document.getElementById("mailfilter").style.display = "flex";
      if (menu != "all" && menu != "Inbox") {
        this.tabtype = "primary";
        document.getElementById("mailfilter").style.display = "none";
      }
      this.labeltype = "changelable";
      this.menutab = menu;
    },
    changelable(lable) {
      document.getElementById("primarytab").click();
      this.menutab = "changelable";
      this.labeltype = lable;
    },
    emailDetailChange() {
      document.querySelectorAll(".message-list li").forEach(function (item) {
        item.addEventListener("click", function () {
          var subjectTitle = item.querySelector(".subject-title").innerHTML;
          document.querySelector(".email-subject-title").innerHTML =
            subjectTitle;

          var emailTitleLeftName = item.querySelector(".title-name").innerHTML;
          document
            .querySelectorAll(".accordion-item.left")
            .forEach(function (subitem) {
              subitem.querySelector(".email-user-name").innerHTML =
                emailTitleLeftName;
              var userImg = item.querySelector(".mail-userimg").value;
              subitem.querySelector("img").setAttribute("src", userImg);
            });

          var messageUserName =
            document.querySelector(".user-name-text").innerHTML;
          var usermailProfile = document
            .querySelector(".header-profile-user")
            .getAttribute("src");
          document
            .querySelectorAll(".accordion-item.right")
            .forEach(function (subitem) {
              subitem.querySelector(".email-user-name-right").innerHTML =
                messageUserName;
              subitem.querySelector("img").setAttribute("src", usermailProfile);
            });
        });
      });
    },
  },
  mounted() {
    document.querySelectorAll(".tabtype a").forEach(function (mailTab) {
      mailTab.addEventListener("click", function () {
        var chatUserList = document.querySelector(".tabtype a.active");
        if (chatUserList) chatUserList.classList.remove("active");
        mailTab.classList.add("active");
      });
    });

    var isShowMenu = false;
    document.querySelectorAll(".email-menu-btn").forEach(function (item) {
      item.addEventListener("click", function (e) {
        e.preventDefault();
        isShowMenu = true;
        document.getElementById("menusidebar").classList.add("menubar-show");
      });
    });

    document
      .querySelector(".email-wrapper")
      .addEventListener("click", function () {
        if (
          document
            .querySelector(".email-menu-sidebar")
            .classList.contains("menubar-show")
        ) {
          if (!isShowMenu) {
            document
              .querySelector(".email-menu-sidebar")
              .classList.remove("menubar-show");
          }
          isShowMenu = false;
        }
      });
  },
};
</script>

<template>
  <b-container fluid>
    <div class="email-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
      <div class="email-menu-sidebar" id="menusidebar">

        <div class="p-4 d-flex flex-column h-100">
          <div class="pb-4">
            <button type="button" class="btn btn-secondary w-100" data-bs-toggle="button">
              <i class=" bx bx-columns me-1"></i> ホーム
            </button>
          </div>

          <div class="d-flex align-items-center mb-2">
            <p class="mb-0 fs-13 text-muted fw-bold">ワークスペース</p>
            <b-link class="btn btn-icon btn-sm btn-light ms-auto">
              <i class="bx bx-plus fs-16"></i>
            </b-link>
          </div>

          <div data-simplebar-track="secondary" class="mx-n4 px-4 email-menu-sidebar-scroll border-bottom border-bottom-dashed border-top border-top-dashed" data-simplebar>

            <div class="gap-3 email-chat-list mx-n4">

              <b-card no-body class="card-height-100 bg-job rounded-0 mt-4" v-bind:class="{ 'bg-info': mode == 'light' }">
                <b-card-body class="p-2">
                  <h6 class="lh-base text-white text-center">個人・チーム勤怠記録</h6>
                  <p class="text-white-75 mb-0 fs-14">勤怠記録はまずワークスペースを作成しましょう！</p>
                  <div class="mt-3">

                    <b-button type="button" variant="light" size="sm" class="w-100">
                      すぐ作成
                      <i class="ri-arrow-right-line align-bottom"></i>
                    </b-button>
                  </div>
                </b-card-body>
              </b-card>

              <!-- <b-link href="javascript: void(0);" class="d-flex align-items-center" active>
                <div class="flex-shrink-0 me-2 avatar-xs chatlist-user-image">
                  <img class="img-fluid rounded-circle" src="@/assets/images/users/avatar-2.jpg" alt="">
                </div>

                <div class="flex-grow-1 chat-user-box overflow-hidden">
                  <h5 class="fs-13 text-truncate mb-0 chatlist-user-name">Scott Median</h5>
                </div>
              </b-link> -->

            </div>
          </div>

          <div class="mt-auto pt-3">
            <b-button variant="light" size="sm" class="w-100">Small button</b-button>
          </div>
        </div>
      </div>

      <div class="email-content">
        <div class="px-4 pt-4 pb-0">
          <div class="border-bottom border-bottom-dashed">
            <b-row class="mt-n2 mb-3 mb-sm-0">
              <b-col sm="auto" class="order-1 d-block d-lg-none">
                <b-button type="button" variant="soft-secondary" size="sm" class="btn-icon fs-16 email-menu-btn">
                  <i class="ri-menu-2-fill align-bottom"></i>
                </b-button>
              </b-col>
              <b-col class="order-3 order-sm-2" sm>
                <div class="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">
                  <b-button type="button" variant="ghost-secondary" size="sm" class="btn-icon fs-16" v-b-tooltip.hover title="Labels">
                    <i class="ri-mail-open-fill align-bottom"></i>
                  </b-button>
                  <b-button type="button" variant="ghost-secondary" size="sm" class="btn-icon fs-16" v-b-tooltip.hover title="Labels">
                    <i class="ri-price-tag-3-fill align-bottom"></i>
                  </b-button>
                </div>
              </b-col>
              <div class="col-auto order-2 order-sm-3">
                <div class="d-flex gap-sm-1 email-topbar-link">
                  <b-button type="button" variant="ghost-secondary" size="sm" class="btn-icon fs-16">
                    <i class="ri-refresh-line align-bottom"></i>
                  </b-button>
                  <div class="dropdown">
                    <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="ri-more-2-fill align-bottom"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">
                      <b-link class="dropdown-item" href="#">Mark as Unread</b-link>
                      <b-link class="dropdown-item" href="#">Mark as Important</b-link>
                      <b-link class="dropdown-item" href="#">Add to Tasks</b-link>
                      <b-link class="dropdown-item" href="#">Add Star</b-link>
                      <b-link class="dropdown-item" href="#">Mute</b-link>
                    </div>
                  </div>
                </div>
              </div>
            </b-row>

            <b-row class="align-items-center mt-3">
              <b-col>
                <ul class="nav nav-tabs nav-tabs-custom nav-primary gap-1 text-center border-bottom-0" role="tablist" id="mailfilter">
                  <li class="nav-item tabtype">
                    <b-link class="nav-link fw-semibold active" id="primarytab" href="#" @click="changetab('primary')">
                      <i class="ri-inbox-fill align-bottom d-inline-block"></i>
                      <span class="ms-1 d-none d-sm-inline-block">Primary</span>
                    </b-link>
                  </li>
                  <li class="nav-item tabtype">
                    <b-link class="nav-link fw-semibold" href="#">
                      <i class="ri-group-fill align-bottom d-inline-block"></i>
                      <span class="ms-1 d-none d-sm-inline-block">Social</span>
                    </b-link>
                  </li>
                  <li class="nav-item tabtype">
                    <b-link class="nav-link fw-semibold" href="#">
                      <i class="ri-price-tag-3-fill align-bottom d-inline-block"></i>
                      <span class="ms-1 d-none d-sm-inline-block">Promotions</span>
                    </b-link>
                  </li>
                </ul>
              </b-col>
              <div class="col-auto">
                <div class="text-muted">1-50 of 154</div>
              </div>
            </b-row>
          </div>
          <div class="message-list-content mx-n4 px-4 message-list-scroll" data-simplebar>
            <div id="elmLoader">
              <div class="spinner-border text-primary avatar-sm" role="status">

              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </b-container>

</template>
